<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="uil uil-house-user display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Applicant list and information</small>
              </div>

              <div class="ms-auto mb-lg-0 mb-2">
                <router-link
                v-if="create_modify_permission"
                class="btn btn-outline-info fw-medium"
                :to="{
                    name: 'New Applicant'
                }"
                >
                <span class="d-none d-lg-block"><i class="uil uil-plus font-size-16 me-1"></i>  Create a New Applicant</span>
                <span class="d-block d-lg-none"><i class="uil uil-plus font-size-16 me-1"></i> New Applicant</span>
                </router-link>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Applicant</label>
                <input
                    type="text"
                    placeholder="Company Name, Contact Name"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchKeyword"
                  />
              </div>
              <div class="col-12 col-md-auto mb-3">
                <label class="form-label mb-1">Sort By</label>
                    <select class="form-select pe-5" v-model="sortBy" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">Default</option>
                      <option value="name_a_z">Company Name (A-Z)</option>
                      <option value="name_z_a">Company Name (Z-A)</option>
                    </select>
              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-md-auto col-lg-4 col-xl-auto">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-md col-lg col-xl-4 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Id</th>
                    <th>Company Name<br>Business Registration Number</th>
                    <th>Date of Registration<br>Type of Entity</th>
                    <th>Company Tel No.<br>Company E-Mail</th>
                    <th>Contact Person<br>Position</th>
                    <th>Contact Tel No.<br>Contact E-Mail Address</th>
                    <th>Created Date<br>Updated Date</th>
                    <th>Status</th>
                    <th v-if="create_modify_permission">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td :colspan="(create_modify_permission) ? 9 : 8" height="300" class="text-center text-muted">
                      <i class="uil uil-house-user me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td :colspan="(create_modify_permission) ? 9 : 8" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  
             
                  <tr v-for="(value, index) in returnData" :key="index" v-show="!loading" >
                    <td>
                      {{ value.id }}
                    </td>
                    <td> 
                      <router-link
                        class="fw-medium"
                        v-b-tooltip.hover
                        :title="value.company_name"
                        :to="{
                           name: 'Edit Applicant',
                           params: { id: value.account_db_code },
                        }"
                        >{{value.company_name}}</router-link
                      >
                      
                     <br>
                      {{ value.business_registration_no }}
                    </td>
                    <td> 
                      {{ value.date_of_registration }}<br>
                      {{ value.type_of_entity.name }}
                    </td>
                    <td> 
                      {{ value.business_tel }}<br>
                      {{ value.business_email }}
                    </td>
                    <td> 
                      {{ value.contact_person }}<br>
                      {{ value.position }}
                    </td>
                    <td> 
                      {{ value.contact_tel }}<br>
                      {{ value.contact_email }}
                    </td>   
                    <td>{{ value.date_created }}<br>{{ value.date_updated || "-" }}</td>
                    <td>
                      <span class="badge bg-soft-success font-size-14" v-if="value.active =='1'">Active</span> 
                      <span class="badge bg-soft-danger font-size-14" v-else-if="value.active =='0'"   
                        v-b-tooltip.hover :title="value.reason_inactive">Inactive</span>
                    </td>
                    <td class="text-center" v-if="create_modify_permission">
                      <router-link
                      class="btn btn-outline-info px-2 py-1 font-size-15 rounded me-2"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                           name: 'Edit Applicant',
                           params: { id: value.account_db_code },
                        }"
                        ><i class="uil uil-edit"></i> </router-link
                      >
                      <button type="button" @click="removeApplicant(value)" v-b-tooltip.hover
                        title="Delete" class="btn btn-outline-danger px-2 py-1 font-size-15 rounded"><i class="uil uil-trash-alt"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <createAutoGenerate ref="modalFunc2" :data="{'accessUsername':accessUsername, 'assessToken':accessToken}" @callParentFunction="handlePageChange(currentPage)"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import createAutoGenerate from "@/views/pages/app/modal/createAutoGenerate";
/**
 * Page
 */
const PageTitle = "Applicants"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    createAutoGenerate
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "IKHLAS",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      searchKeyword: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false

    };
  },
  middleware: "authentication",
  async mounted(){
    await this.reload()
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  methods:{
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.searchKeyword);
        //this.exportData(1)
      },
      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.sortBy= ""
        this.searchKeyword= ""
        this.filterType="created_date"
        this.date_range = this.$refs.commonFunc.last30Days()
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("sortBy", this.sortBy);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        console.log(`page:${pages}, limit:${this.perPage}, keyword:${keyword}, sortBy:${this.sortBy}, dateStart:${this.date_range[0]}, dateEnd:${this.date_range[1]}`)
        axios({
            // method: "get",
            // url: `/api/applicants.json`,
            method:"post",
            url: appConfig.APIHost + 'controller/applicant/getAllApplicantList',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total
            var responseData = resData.data
            
            if (resData.status == 200) {
              this.returnData = responseData
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    removeApplicant(value) {
      Swal.fire({
        icon: 'info',
        title: `Confirm Applicant Deletion?`,
        html: `<p>Are you sure you want to delete this ${value.company_name}? </p><p class="mb-0">This action is irreversible, so make sure you want to proceed.</p>`,
        confirmButtonColor: '#FA6541',
        iconColor: '#202360',
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.loading = true
          this.disabled = true
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", value.account_db_code);
          axios({
            method: "post",
            url: appConfig.APIHost + "controller/applicant/deleteApplicant",
            data: bodyFormData,
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }).then((response) => {
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.handlePageChange(1)
              Swal.fire({
                icon: 'success',
                title: `${value.company_name} Deleted Successfully`,
                html: 'The selected applicant has been successfully deleted.',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              })
            } else if (resData.status == 440) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            }
            this.loading = false
            this.disabled = false
            this.$Progress.finish();
          }).catch((error) => {
            this.disabled = false;
            this.loading = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }
      })
      
      
    },
    async exportData(){
        this.$Progress.start();
        this.disableExport = true;
        this.loadingButton = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        // bodyFormData.append("page", pages);
        // bodyFormData.append("limit", this.perPage);
        axios({
            method: "get",
            url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.export_data = [];
            var resData = response.data;
            this.totalData = resData.total
            var users = resData.users
           

            for (var i in users) {
                 // Combine date and time
                 const depositDateTime = `29 Jun 2023, 12:30:00`;
                // Generate a random deposit amount
                const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

                // Calculate merchant fee and nett amount
                const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                const nettAmount = randomDeposit - merchantFee;
                const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                var transaction_id = ""
                if (randomMode=="UPI"){
                  transaction_id = "320020673657"
                }else{
                  transaction_id = "IMPS/123443211240"
                }

                const listData = {
                  "id":"ids",
                  "deposit_date": depositDateTime,
                  "customer_id": `${users[i].id}`,
                  "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                  "deposit_amount": randomDeposit.toString(),
                  "deposit_mode":randomMode.toString(),
                  "merchant_fee": merchantFee.toString(),
                  "nett_amount": nettAmount.toString(),
                  "transaction_id":transaction_id,
                  "status": randomStatus,
                };
                this.export_data.splice(i, 0, listData);
              }
              var self = this
              setTimeout(function() {  
                self.disableExport = false;
                self.loadingButton = false;
                self.exportToExcel(self.export_data,'Summary','xlsx')
              }, 1200);
             
            // if (resData.status == 200) {
            //   console.log(resData)
            //   this.totalData = 10
            // } 
            // else if (resData.status == 440){
            //   Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            //     localStorage.clear();
            //     this.$router.push({
            //         path: "/login",
            //   });
            // }
            // else {
            //     Swal.fire(
            //       {
            //       icon: 'error',
            //       title: 'Oops...',
            //       html: `${resData.message}.`,
            //       confirmButtonColor: '#222',
            //       confirmButtonText: this.$t('siteLang.Done'),
            //     })
            // }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disableExport = false;
            this.loadingButton = false;
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='applicant')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "applicant" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>